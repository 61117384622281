/*
    -- @ SELECT2 @ --
    * @version : 4.0.4
    * Make Select cool cool
    * Documentation : https://select2.org/
    * Used in homepage in search form
*/


(function ($) {

    $(function () {

        // HOMEPAGE : Search form - Countries
        if($('.js-select2-countries').length) {
            var $element = $('.js-select2-countries');

            $element.select2({

                placeholder: "Destination",
                // allowClear: true,
                width: '100%',
                language: 'fr',
                sorter: function(data) {
                    var query = $('.select2-search__field').val().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                    return data.sort(function(a, b) {

                        a = a.text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                        b = b.text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                        if (a.indexOf(query) > b.indexOf(query)) {
                            return 1;
                        } else if (a.indexOf(query) < b.indexOf(query)) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                }
                // minimumInputLength: 3 // only start searching when the user has input 3 or more characters
            });

            $element.on("select2:open", function() {
                $(".select2-search__field").attr("placeholder", "Tapez votre destination");
            });
            $element.on("select2:close", function() {
                $(".select2-search__field").attr("placeholder", null);
            });
        }

        if($('.js-select2-survey').length) {
            var $element3 = $('.js-select2-survey');

            $element3.select2({
                placeholder: "Destination",
                allowClear: true,
                width: '100%',
                language: 'fr',
                // minimumInputLength: 3 // only start searching when the user has input 3 or more characters
            });
        }


        // CUSTOM TRIP PAGE : Search form - Countries
        if($('.js-select2-trip').length) {
            var $element = $('.js-select2-trip');

            $element.select2({
                placeholder: "Voyage",
                //allowClear: true,
                width: '100%',
                language: 'fr',
                // minimumInputLength: 3 // only start searching when the user has input 3 or more characters
            });

            // When a result is selected, fill the "Code" trip input with the value
            $element.on('select2:select', function (e) {
                var data = e.params.data;

                // Fill "reference" input when a result is choosen
                $('.js-filled-by-select2').val($(data.element).data('reference'))

            });

            // When a result is unselected, empty the "Code" trip input with the value
            $element.on('select2:unselect', function (e) {
                // Empty "reference" input when a result is reset
                $('.js-filled-by-select2').val('')
            });
        }

    });
})(jQuery);
