(($) => {
    $(() => {

        let $checkAlreadyCustomer = $('#registration_form_isAlreadyCustomer');
        let $tagRegistration = $('#registration_form');

        $('#registration_form_confirmPassword').attr('required', false);
        $('#registration_form_confirmPassword').attr('tabindex', -1);

        if ($checkAlreadyCustomer.length > 0) {
            // Show/hide N° client or DOB input
            $checkAlreadyCustomer.on('change', function () {

                // If checkbox is checked
                if ($checkAlreadyCustomer.is(':checked')) {
                    $('.dob-client').addClass('shown');
                } else {
                    $('.dob-client').removeClass('shown');
                }
            });
        }
    })
})(jQuery);

