(($) => {
    $(() => {

        let onResearchRoutes = onPath('/voyage-*');

        if (!(onPath('/') || onResearchRoutes)) {
            return;
        }

        initModal();
        initDropDowns();
        initFiltersFromForm();
        initHighlightedFilters();
        bindFormChange();

        if (typeof BEHAVIOR !== "undefined" && BEHAVIOR == "home") {
            $('.btn-filter-submit').on('click', function (e) {
                document.location.href = generateUrl("https://www.tirawa.com/voyages-recherches");
            });
        }
    });


    function initFiltersFromForm() {
        const form = $('#research_form');
        const modal = $('#country-filters-modal');
        const highlightedFilters = $('.filters-highlighted-wrapper');

        $('#country-filters-modal').find('.filter-choice').on('filter-change', function (e) {
            const filterKey = $(this).parents('[data-filter]').attr('data-filter');
            const filterValue = $(this).attr('data-value');
            const isSelected = $(this).hasClass('selected');
            form.find('input[type="checkbox"][name^="research_form[' + filterKey + ']"][value="' + filterValue + '"]').prop("checked", isSelected).trigger('change');

            const highlightedFilter = highlightedFilters.find('.filter-name-' + filterKey).find('.filter-choice[data-value="' + filterValue + '"]');
            highlightedFilter.toggleClass('selected');
            updateAllHighlitedFiltersValue();
        });

        form.find('input[type="checkbox"]:checked').each(function () {
            const value = $(this).val();
            const filterName = $(this).attr('name').replace('research_form[', '').replace(']', '').replace('[]', '');
            modal.find('.filter-name-' + filterName).find(".filter-choice[data-value=\"" + value + "\"]").addClass("selected").trigger("filter-change");
        });

        updateAllHighlitedFiltersValue();
    }

    function initDropDowns() {

        const modal = $('#country-filters-modal');
        modal.find('.filter-choice').on('click', function (e) {
            $(this).toggleClass("selected").trigger('filter-change');
        });

        //Show / hide dropdown values
        $(document).find(".trigger-filter-dropdown").on('click', function (e) {
            const currentDropdown = $(this).parent().find('.filter-choices');
            $('.filter-list .filter-choices').not(currentDropdown).removeClass('shown');
            $('.filter-list .filter-choices').not(currentDropdown).find('input').val('').trigger('keyup');
            currentDropdown.toggleClass('shown');
        });

        //Close dropdown on click outside
        $(document).on('click', function (e) {
            if (!$(e.target).closest('.filter-choices').length && !$(e.target).closest('.trigger-filter-dropdown').length) {
                $('.filter-choices').removeClass('shown');
            }
        });

        $(document).find('.filter-list').each(function () {
            const list = $(this);

            //Filter on lists and hide the items not matching the search
            list.find('.search input').on('keyup', function (e) {
                const value = $(this).val().toLowerCase();
                if (value === '') {
                    list.find('.filter-choice').show();
                    return;
                }

                const matched = list.find('.filter-choice').filter(function () {
                    $(this).toggle($(this).find('.label').text().toLowerCase().indexOf(value) > -1)
                });
            });

            //Update selected values for list
            list.find('.filter-choice').on('filter-change', function (e) {
                const selected = list.find('.filter-choice.selected').map(function () {
                    return $(this).find('.label').text();
                }).get();
                list.find('.selected-values').html('');
                $(selected).each(function (i, name) {
                    list.find('.selected-values').append('<span class="selected-value">' + name + '<i class="fa-solid fa-times"></i></span>');
                });

                //Delete item
                list.find('.selected-value i').on('click', function (e) {
                    const value = $(this).parent().text().toLowerCase();
                    list.find('.filter-choice').each(function (i, el) {
                        if ($(el).find('.label').text().toLowerCase().indexOf(value) > -1) {
                            $(el).removeClass('selected').trigger('filter-change');
                        }
                    });
                });
            });
        });
    }

    function initModal() {
        /** Modal handling **/
        const modal = $('#country-filters-modal');
        $('.toggle-more-filters').on('click', function () {
            modal.toggleClass("shown");
            $('body').toggleClass('modal-filter-open');
        });

        modal.find('.reset-filters').on('click', function (e) {
            modal.find('.filter-choice').removeClass('selected').trigger('filter-change');
        });

        modal.find('.btn-filter-submit').on('click', function (e) {
            modal.removeClass('shown');
            $('body').removeClass('modal-filter-open');
        });
    }

    function initHighlightedFilters() {
        //Bind highlighted filters to their respective dropdowns in the modal
        const modal = $('#country-filters-modal');
        const highlightedFilters = $('.filters-highlighted-wrapper .filter-list')

        highlightedFilters.find('.filter-choice').on('click', function (e) {
            //Make the same change in the modal
            const filterBlock = $(this).parents('.filter-list');
            const value = $(this).attr('data-value');
            const modalFilter = modal.find('.filter-name-' + filterBlock.attr('data-filter')).find('.filter-choice[data-value="' + value + '"]');
            modalFilter.toggleClass('selected').trigger('filter-change');
            showFilterValueLabel(filterBlock);
        });
    }

    function updateAllHighlitedFiltersValue() {
        const highlightedFilters = $('.filters-highlighted-wrapper .filter-list')
        highlightedFilters.each(function () {
            showFilterValueLabel($(this));
        });
    }

    function showFilterValueLabel(filter) {
        const selectedValues = filter.find('.selected');
        const filterLabel = filter.find('.filter-value');

        filterLabel.removeClass('multiple');
        if (selectedValues.length == 1 && BEHAVIOR != "home") {
            const value = selectedValues.text();
            filterLabel.text(value);
        } else if (selectedValues.length > 0) {
            filterLabel.addClass('multiple');
            filterLabel.text(selectedValues.length);
        }
    }

    let searchTimeout = null;

    function bindFormChange() {
        const form = $('#research_form');

        form.find('input').on('change', function (e) {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(sendSearch, 500);
        });
    }

    function sendSearch() {
        const form = $('#research_form');

        //Serialize data
        const page = new URLSearchParams(window.location.search).get('page') || 1;

        //get current page url but delete the page parameter
        const currentPage = window.location.href;
        const url = currentPage.split('?')[0];
        const params = form.serializeArray().filter(function (item) {
            return item.name !== 'research_form[_token]';
        });
        params.push({name: 'page', value: page});

        if (BEHAVIOR == "search") {
            updateUrl();
            $.post(url, params, function (response) {
                $('.filters-nb-results').text(response.nb_results);
                $('.contain-trip-results').html(response['content']);
            });
        }
    }

    function updateUrl() {
        const url = generateUrl();

        //Replace current url
        window.history.pushState({}, '', url);
    }

    function generateUrl(baseUrl) {
        //Read yaml file to get params name
        const form = $('#research_form');

        let uriParams = [];
        let url = "";
        const params = form.serializeArray().filter(function (item) {
            return item.name !== 'research_form[_token]';
        });

        if (baseUrl === undefined) {
            url = new URL(window.location.href);
        } else {
            url = new URL(baseUrl);
        }

        //Serialize data
        const page = new URLSearchParams(window.location.search).get('page') || 1;

        //Remove all params from url
        url.search = '';

        params.forEach(function (item) {
            const filterName = item.name.replace('research_form[', '').replace(']', '').replace('[]', '');
            const filter = findParameterFromName(filterName);
            if (filter) {
                //Check if uriParams already have a filter with this name and add the value
                const existingFilter = uriParams.find(function (item) {
                    return item.name === filter.name;
                });
                if (existingFilter) {
                    existingFilter.values.push(item.value);
                } else {
                    uriParams.push({
                        term: filter.term,
                        name: filter.name,
                        values: [item.value]
                    });
                }
            }
        });

        //Update url
        uriParams.forEach(function (item) {
            url.searchParams.append(item.term, item.values.join(','));
        });

        //Add page
        url.searchParams.append('page', page);
        return url;
    }

    function findParameterFromName(name) {
        return FILTER_DEFINITIONS.find(function (item) {
            return item.name === name;
        });
    }
})(jQuery);

